import React from "react"
import Layout from "../components/layout"
import Article from "../components/article"
import ownYourError from "../images/own-your-error.jpg"

const About = () => {
    return (
        <Layout headerText="about me">
            <Article header="oopss" shortDescription="still nothing there :)" image={ownYourError}>
            </Article>
        </Layout>
    )
}

export default About