import React from "react"
import Navigation from "./navigation"
import "../styles/main.scss"

const Layout = ({ children, headerText }) => {
    return (
        <main>
            <Navigation headerText={headerText}/>
            { children }
        </main>
    )
}

export default Layout