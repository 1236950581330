import React from "react"
import { Link } from "gatsby"

const Article = ({ children, header, shortDescription, image, linkTo, linkToText }) => {
    return (
        <article>
            <div className="info">
                <h1>{ header }</h1>
                <img src={image} alt="article-img" rel="noreferrer" width={333}/>
                <p>{ shortDescription }</p>
                <Link to={linkTo}>{ linkToText }</Link>
            </div>
            <div className="context">{ children }</div>
        </article>
    )
}

export default Article