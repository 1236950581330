import React from 'react'
import { Link } from 'gatsby'

const Navigation = ({ headerText }) => {
    return (
        <nav>
            <h1>jne9703 - { headerText }</h1>
            <ul>
                <li>
                    <Link to="/" activeClassName="active">index page</Link>
                </li>
                <li>
                    <Link to="/projects" activeClassName="active">projects</Link>
                </li>
                <li>
                    <Link to="/about" activeClassName="active">about me</Link>
                </li>
            </ul>
        </nav>
    )
}

export default Navigation